<template>
  <div class="cashier">
    <div class="title">
      <span>{{ Time }}，</span>请您尽快完成付款，超时订单将自动取消
    </div>
    <div class="content">
      <p>
        请使用支付宝、微信等App扫码支付，应付订单金额：<span>{{
          totalAmount
        }}</span
        >元
      </p>
      <div class="img-box">
        <div v-loading="loading">
          <img :src="codeUrl" alt="" />
        </div>
        <!-- <img src="@/assets/images/skm.png" alt="" /> -->
        <img src="@/assets/images/sys.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { decimals, countDown } from "@/utils/validate";
export default {
  name: "Cashier",

  data() {
    return {
      codeUrl: "",
      loading: true,
      Time: "",
      totalAmount: 0,
      orderNum: "",
    };
  },
  watch: {
    loading: {
      handler: function (val, oldVal) {
        if (!val) {
          this.queryPaymentState();
        }
      },
      deep: true,
      immediate: true,
    },
    orderNum: {
      handler: function (val, oldVal) {
        console.log(val);
        val && this.getCode();
      },
      deep: true,
      // immediate: true,
    },
  },
  created() {
    // this.orderInformation = JSON.parse(sessionStorage.getItem("order"));
    this.totalAmount = decimals(Number(sessionStorage.getItem("totalAmount")));
    this.orderNum = this.$route.query.orderNum;

    this.ip = sessionStorage.getItem("ip");
    this.CountDown();

    // sessionStorage.removeItem("oder");
    //  Payment/getOrderCode
    // {
    //   "object": {
    //     "dealNumber": "DD202203180003",
    //     "spbillCreateIp": "113.12.12.333"
    //   }
    // }
  },
  beforeDestroy() {
    clearInterval(this.t);
  },

  methods: {
    goAccomplish() {
      this.$router.push({
        path: `CompletionOfPayment`,
      });
    },
    async getCode() {
      clearTimeout(this.t1);
      const data = await this.$API.order.getOrderCode({
        object: {
          dealNumber: this.orderNum.split(",")[0],
          spbillCreateIp: this.ip,
        },
      });

      if (data.data.code === "SUCCESS") {
        this.codeUrl = data.data.data.codeImgUrl;
        this.loading = false;
      } else {
        this.t1 = setTimeout(() => {
          this.getCode();
        }, 3000);
      }
    },
    async queryPaymentState() {
      const data = await this.$API.order.queryPaymentState({
        object: {
          dealNumber: this.orderNum,
        },
      });
      // console.log(data.data.code);
      // if (data.data.code === "SUCCESS") {
      //   clearTimeout(this.t2);
      //   this.$router.replace({
      //     path: `CompletionOfPayment?orderNum=${this.$route.query.orderNum}`,
      //   });
      // } else {
      //   this.t2 = setTimeout(() => {
      //     this.queryPaymentState();
      //   }, 5000);
      // }
    },

    CountDown() {
      // if (this.orderInformation.tradeTime) {
      //   this.Time = countDown(this.orderInformation.tradeTime);
      //   this.t = setInterval(() => {
      //     this.Time = countDown(this.orderInformation.tradeTime);
      //   }, 1000);
      // } else {

      this.Time = countDown(Number(sessionStorage.getItem("Time")));
      this.t = setInterval(() => {
        this.Time = countDown(Number(sessionStorage.getItem("Time")));
      }, 1000);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.cashier {
  width: 1200px;
  width: 1200px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  opacity: 1;
  .title {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    color: #666666;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;
    span {
      color: #fe3132;
    }
  }
  .content {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    p {
      margin: 16px 0 24px 0;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
      span {
        font-size: 18px;
        font-weight: bold;
        color: #fe3132;
      }
    }
    .img-box {
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        margin-left: 21px;
      }
      div {
        width: 301px;
        height: 301px;
      }
    }
  }
}
</style>